import LabelAndTextInput from '../inputs/LabelAndTextInput'
import RadioGroupAndLabel from '../radio/RadioGroupAndLabel'
import Title from '../title/Title'
import { setHasNotary, setCity, setName } from '@components/form/sections/notarySlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'
import { requiredValidator, defaultNoNumberInputValidator } from '@/utils/validators'

import './Section.css'

function Notary() {
	const dispatch = useAppDispatch()

	const getHasNotary = useAppSelector((state: RootState) => state.notary.hasNotary)
	const onSetHasNotary = (value: string) => {
		dispatch(setHasNotary(value))
	}

	const getName = useAppSelector((state: RootState) => state.notary.name)
	const onSetName = (value: string) => {
		dispatch(setName(value))
	}

	const getCity = useAppSelector((state: RootState) => state.notary.city)
	const onSetCity = (value: string) => {
		dispatch(setCity(value))
	}

	return (
		<div className='section'>
			<Title>Votre notaire</Title>
			<div className='formContent'>
				<div className='fieldLine'>
					<RadioGroupAndLabel
						id='hasNotary'
						label='Avez-vous un notaire ?'
						options={{ no: 'Non', yes: 'Oui' }}
						defaultChecked={getHasNotary}
						onChange={onSetHasNotary}
						validator={(input: string) => [requiredValidator(input)]}
					/>
				</div>
				{getHasNotary === 'hasNotary-yes' && (
					<div className='fieldLine'>
						<LabelAndTextInput
							id='notaryName'
							label='Nom du notaire'
							name='lastName'
							placeholder='Ex : Jean Dupont'
							value={getName}
							onChange={onSetName}
							validator={defaultNoNumberInputValidator}
							autocomplete='family-name'
						/>
						<LabelAndTextInput
							id='notaryCity'
							label='Ville du notaire'
							name='city'
							placeholder='Ex : Paris'
							value={getCity}
							onChange={onSetCity}
							validator={defaultNoNumberInputValidator}
							autocomplete='address-level2'
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default Notary
