import React, { useState } from 'react'

import Label from '../labels/Label'
import type { validatorFunc } from '@/utils/validatorProvider'
import Validator from '@/components/form/validator/Validator'

import './SelectBoxAndLabel.css'

interface SelectBoxAndLabelProps {
	id: string
	text: string
	options: { [key: string]: string }
	onChange: (value: string) => void
	validator?: validatorFunc
	value?: string
	style?: React.CSSProperties
}

function SelectBoxAndLabel(props: SelectBoxAndLabelProps) {
	const [error, setError] = useState<string[]>([])
	const ref = React.createRef<HTMLSelectElement>()

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		props.onChange(e.target.value)
	}

	const handleError = (errors: string[]) => {
		setError(errors)
	}

	return (
		<Validator id={props.id} validator={props.validator} reference={ref} onError={handleError}>
			<div style={props.style} className='SelectBoxAndLabel'>
				<Label htmlFor={props.id}>{props.text}</Label>
				{error.length > 0 && <span className='error'>{error}</span>}
				<select id={props.id} ref={ref} value={props.value} onChange={handleChange}>
					{Object.keys(props.options).map(key => {
						return (
							<option key={key} value={key}>
								{props.options[key]}
							</option>
						)
					})}
				</select>
			</div>
		</Validator>
	)
}

export default SelectBoxAndLabel
