import React, { createContext, useContext, useState } from 'react'

export type validationResult = {
	valid: boolean
	error: string
}

export type validatorFunc = {
	(value: string): validationResult[]
}

export interface Validator {
	id: string
	validate: validatorFunc
	ref: HTMLInputElement | HTMLSelectElement | null
	setter: any
}

export interface ValidatorContextProps {
	register: (validator: Validator) => void
	validate: () => boolean
}

interface ValidatorProviderProps {
	children: React.ReactNode
}

const ValidatorContext = createContext<ValidatorContextProps | null>(null)

const ValidatorProvider: React.FC<ValidatorProviderProps> = ({ children }) => {
	const [validators, setValidators] = useState<{ [id: string]: Validator }>({})

	const register = (validator: Validator) => {
		const newValidator: { [id: string]: Validator } = { [validator.id]: validator }
		setValidators(validators => ({ ...validators, ...newValidator }))
	}

	const validate = (): boolean => {
		let keys: keyof typeof validators
		let isValid = true

		for (keys in validators) {
			const validator = validators[keys]
			if (!validator.ref) continue

			const results = validator.validate(validator.ref.value)
			const errors = results.reduce<string[]>((acc: string[], result: validationResult) => {
				return result.valid ? acc : [...acc, result.error]
			}, [])

			console.log('validator', validator)
			console.log('input value', validator.ref.value)
			console.log('results', results)

			if (errors.length > 0) {
				isValid = false
			}

			validator.setter(errors)
		}

		return isValid
	}

	return (
		<ValidatorContext.Provider value={{ register, validate }}>{children}</ValidatorContext.Provider>
	)
}

function useValidatorContext() {
	const context = useContext(ValidatorContext) as ValidatorContextProps

	if (context === undefined) {
		throw new Error('useValidatorContext must be used within a ValidatorProvider')
	}
	return context
}

export { ValidatorProvider, useValidatorContext }
export type { Validator as ValidatorType }
