import React, { useRef, useState } from 'react'
import { setTermsAndConditions } from '@components/form/sections/termsSlice'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'
import Validator from '@/components/form/validator/Validator'
import { requiredValidator } from '@/utils/validators'

import CheckAndLabel from '../check/CheckAndLabel'

function Terms() {
	const dispatch = useAppDispatch()
	const [error, setError] = useState<string[]>([])
	const ref = useRef<any>({ value: '' })

	const validator = (input: string) => [
		requiredValidator(input, "Veuillez accepter les conditions d'utilisation."),
	]
	const id = 'termsAndConditions'

	const getTerms = useAppSelector((state: RootState) => state.terms.termsAndConditions)

	const handleSetTerms = () => {
		console.log('Terms: ', getTerms)
		ref.current.value = !getTerms ? 'true' : ''
		dispatch(setTermsAndConditions(!getTerms))
	}
	const handleError = (errors: string[]) => {
		setError(errors)
	}

	return (
		<Validator id={id} validator={validator} reference={ref} onError={handleError}>
			{error.length > 0 && <span className='error'>{error}</span>}
			<CheckAndLabel
				id={id}
				name={id}
				text="J'ai lu et j'accepte les conditions générales de vente"
				onChange={handleSetTerms}
			/>
		</Validator>
	)
}

export default Terms
