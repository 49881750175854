import { createSlice } from '@reduxjs/toolkit'

interface ICorporation {
	corporationName: string
	corporationCapital: string
	corporationAddress: string
	corporationSiren: string
	corporationRegistar: string
	corporationDelegateGender: string
	corporationDelegateLastName: string
	corporationDelegateFirstName: string
	corporationDelegatePhone: string
	corporationDelegateEmail: string
	corporationDelegateAddress: string
	corporationKbisFile: string
	corporationStatutsFile: string
	corporationDelegateIdFile: string
	corporationNonFinancingDeclarationFile: string
}

const newCorporation: ICorporation = {
	corporationName: '',
	corporationCapital: '',
	corporationAddress: '',
	corporationSiren: '',
	corporationRegistar: '',
	corporationDelegateGender: '',
	corporationDelegateLastName: '',
	corporationDelegateFirstName: '',
	corporationDelegatePhone: '',
	corporationDelegateEmail: '',
	corporationDelegateAddress: '',
	corporationKbisFile: '',
	corporationStatutsFile: '',
	corporationDelegateIdFile: '',
	corporationNonFinancingDeclarationFile: '',
}

type CorporationState = {
	corporations: ICorporation[]
}

const initialState: CorporationState = {
	corporations: [],
}

export const corporationSlice = createSlice({
	name: 'Corporation',
	initialState,
	reducers: {
		addCorporation: (state, action) => {
			if (state.corporations[action.payload]) {
				return
			}
			state.corporations.push(newCorporation)
		},
		removeCorporation: (state, action) => {
			state.corporations = state.corporations.filter((_, index) => index !== action.payload)
		},
		setCorporationName: (state, action) => {
			state.corporations[action.payload.index].corporationName = action.payload.value
		},
		setCorporationCapital: (state, action) => {
			state.corporations[action.payload.index].corporationCapital = action.payload.value
		},
		setCorporationAddress: (state, action) => {
			state.corporations[action.payload.index].corporationAddress = action.payload.value
		},
		setCorporationSiren: (state, action) => {
			state.corporations[action.payload.index].corporationSiren = action.payload.value
		},
		setCorporationRegistar: (state, action) => {
			state.corporations[action.payload.index].corporationRegistar = action.payload.value
		},
		setCorporationDelegateGender: (state, action) => {
			state.corporations[action.payload.index].corporationDelegateGender = action.payload.value
		},
		setCorporationDelegateLastName: (state, action) => {
			state.corporations[action.payload.index].corporationDelegateLastName = action.payload.value
		},
		setCorporationDelegateFirstName: (state, action) => {
			state.corporations[action.payload.index].corporationDelegateFirstName = action.payload.value
		},
		setCorporationDelegatePhone: (state, action) => {
			state.corporations[action.payload.index].corporationDelegatePhone = action.payload.value
		},
		setCorporationDelegateEmail: (state, action) => {
			state.corporations[action.payload.index].corporationDelegateEmail = action.payload.value
		},
		setCorporationDelegateAddress: (state, action) => {
			state.corporations[action.payload.index].corporationDelegateAddress = action.payload.value
		},
		setCorporationKbisFile: (state, action) => {
			state.corporations[action.payload.index].corporationKbisFile = action.payload.value
		},
		setCorporationStatutsFile: (state, action) => {
			state.corporations[action.payload.index].corporationStatutsFile = action.payload.value
		},
		setCorporationDelegateIdFile: (state, action) => {
			state.corporations[action.payload.index].corporationDelegateIdFile = action.payload.value
		},
		setCorporationNonFinancingDeclarationFile: (state, action) => {
			state.corporations[action.payload.index].corporationNonFinancingDeclarationFile =
				action.payload.value
		},
	},
})

export const {
	addCorporation,
	removeCorporation,
	setCorporationName,
	setCorporationCapital,
	setCorporationAddress,
	setCorporationSiren,
	setCorporationRegistar,
	setCorporationDelegateGender,
	setCorporationDelegateLastName,
	setCorporationDelegateFirstName,
	setCorporationDelegatePhone,
	setCorporationDelegateEmail,
	setCorporationDelegateAddress,
	setCorporationKbisFile,
	setCorporationStatutsFile,
	setCorporationDelegateIdFile,
	setCorporationNonFinancingDeclarationFile,
} = corporationSlice.actions

export default corporationSlice.reducer
export type { ICorporation }
