import React from 'react'

import './Modal.css'

interface ModalProps {
	children: React.ReactNode
}

function Modal(props: ModalProps) {
	return (
		<div className='modalBackground'>
			<div className='modalForeground'>{props.children}</div>
		</div>
	)
}

export default Modal
