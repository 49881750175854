import Label from '../labels/Label'

import '../radio/RadioAndLabel.css'

interface CheckAndLabelProps {
	id: string
	name: string
	text: string
	onChange: () => void
}

function CheckAndLabel(props: CheckAndLabelProps) {
	const handleChange = () => {
		props.onChange()
	}

	return (
		<div className='RadioAndLabel'>
			<input
				type='checkbox'
				id={props.id}
				name={props.name}
				value={props.id}
				onChange={handleChange}
			/>
			<Label htmlFor={props.id}>{props.text}</Label>
		</div>
	)
}

export default CheckAndLabel
