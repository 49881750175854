import Label from '../labels/Label'

import './RadioAndLabel.css'

interface RadioAndLabelProps {
	id: string
	name: string
	label: string
	defaultChecked?: string
	onChange: (value: string) => void
}

function RadioAndLabel(props: RadioAndLabelProps) {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.onChange(e.target.value)
	}

	return (
		<div className='RadioAndLabel'>
			<input
				type='radio'
				id={props.id}
				name={props.name}
				value={props.id}
				defaultChecked={props.id === props.defaultChecked}
				onChange={handleChange}
			/>
			<Label htmlFor={props.id}>{props.label}</Label>
		</div>
	)
}

export default RadioAndLabel
