import React, { useEffect } from 'react'

import type { validatorFunc } from '@/utils/validatorProvider'
import { useValidatorContext } from '@/utils/validatorProvider'

interface ValidatorProps {
	id: string
	children: React.ReactNode
	validator?: validatorFunc
	reference: React.RefObject<any>
	onError: (errors: string[]) => void
}

function Validator(props: ValidatorProps) {
	const validator = useValidatorContext()

	useEffect(() => {
		if (props.validator) {
			validator.register({
				id: props.id,
				validate: props.validator,
				ref: props.reference.current,
				setter: props.onError,
			})
		}
	}, []) //eslint-disable-line

	return <div>{props.children}</div>
}

export default Validator
