import React, { ChangeEvent, DragEvent, useEffect, useState, useRef } from 'react'

import ButtonIconText from '@components/form/buttons/ButtonIconText'
import ajouter from '@assets/form/ajouter.svg'

import { Document, Thumbnail } from 'react-pdf'

import './File.css'

interface FileProps {
	id: string
	placeholder: string
	label: string
	onChange?: (value: string) => void
	style?: React.CSSProperties
}

function File(props: FileProps) {
	const [dragActive, setDragActive] = useState(false)
	const [url, setUrl] = useState<string>('')
	const [file, setFile] = useState<File | null>(null)
	const fileContenerRef = useRef<HTMLDivElement>(null)
	const [dimensions, setDimensions] = useState({ width: 1, height: 2 })

	React.useEffect(() => {
		const ref = fileContenerRef
		if (ref.current) {
			const { current } = ref
			const boundingRect = current.getBoundingClientRect()
			const { width, height } = boundingRect
			setDimensions({ width: Math.round(width), height: Math.round(height) })
		}
	}, [])

	useEffect(() => {
		return () => {
			if (url) {
				URL.revokeObjectURL(url)
			}
		}
	}, []) //eslint-disable-line

	const handleDrag = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === 'dragenter' || e.type === 'dragover') {
			setDragActive(true)
		} else if (e.type === 'dragleave') {
			setDragActive(false)
		}
	}

	const handleDrop = function (e: DragEvent<HTMLDivElement>) {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		if (e.dataTransfer.files && e.dataTransfer.files[0]) {
			// at least one file has been dropped so do something
			// handleFiles(e.dataTransfer.files);
			setFile(e.dataTransfer.files[0])
			const url = URL.createObjectURL(e.dataTransfer.files[0])
			setUrl(url)
			if (props.onChange) {
				props.onChange(url)
			}
		}
	}

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		if (e.target.files && e.target.files[0]) {
			console.log('file handleChange: ' + e.target.files[0].name)
			setFile(e.target.files[0])
			const url = URL.createObjectURL(e.target.files[0])
			setUrl(url)
			if (props.onChange) {
				props.onChange(url)
			}
		}
	}

	const fileRender = () => {
		const imgRender = () => {
			return <img src={url} alt={file?.name} />
		}

		const pdfRender = () => {
			return (
				<Document file={file}>
					<Thumbnail pageNumber={1} width={dimensions.width} />
				</Document>
			)
		}

		if (file && url !== '') {
			return (
				<div className='fileRender'>
					<p>{file.name}</p>
					{file.type === 'application/pdf' ? pdfRender() : imgRender()}
				</div>
			)
		}
		return (
			<div className='fileRender'>
				<img src={props.placeholder} alt={props.id} />
			</div>
		)
	}

	return (
		<div className='file'>
			<h3>{props.label}</h3>
			<div className='fileBox' onDragEnter={handleDrag} style={props.style}>
				<input type='file' id='input-file-upload' multiple={false} onChange={handleChange} />
				<label id='label-file-upload' htmlFor='input-file-upload'>
					<div className='fileContent' ref={fileContenerRef}>
						{fileRender()}
						<ButtonIconText icon={ajouter} text='Sélectionner un fichier' onClick={() => {}} />
					</div>
				</label>
				{dragActive && (
					<div
						id='drag-file-element'
						onDragEnter={handleDrag}
						onDragLeave={handleDrag}
						onDragOver={handleDrag}
						onDrop={handleDrop}
					></div>
				)}
			</div>
		</div>
	)
}

export default File
