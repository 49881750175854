import { createSlice } from '@reduxjs/toolkit'

type NotaryState = {
	hasNotary: string
	name: string
	city: string
}

const initialState: NotaryState = {
	hasNotary: '',
	name: '',
	city: '',
}

export const notarySlice = createSlice({
	name: 'Notary',
	initialState,
	reducers: {
		setHasNotary: (state, action) => {
			state.hasNotary = action.payload
		},
		setName: (state, action) => {
			state.name = action.payload
		},
		setCity: (state, action) => {
			state.city = action.payload
		},
	},
})

export const { setHasNotary, setName, setCity } = notarySlice.actions

export default notarySlice.reducer
