import Title from '../title/Title'
import File from '../files/File'
import add from '@assets/form/plus.svg'
import { useAppDispatch, useAppSelector } from '@/hooks'
import type { RootState } from '@/store'
import type { IPerson } from './personSlice'
import type { ICorporation } from './corporationSlice'
import { setIdFile, setNonFinancingDeclarationFile } from '@components/form/sections/personSlice'
import {
	setCorporationKbisFile,
	setCorporationStatutsFile,
} from '@components/form/sections/corporationSlice'
import {
	setCorporationDelegateIdFile,
	setCorporationNonFinancingDeclarationFile,
} from '@components/form/sections/corporationSlice'

import './Section.css'

interface FilesProps {
	buyerType: string
	fundingType: string
}

function Files(props: FilesProps) {
	const dispatch = useAppDispatch()

	// **** getters ****
	const persons = useAppSelector((state: RootState) => state.person.persons)
	const corporations = useAppSelector((state: RootState) => state.corporation.corporations)

	const fullName = (person: IPerson) => {
		return `${person.firstName} ${person.lastName}`
	}

	const corpoDelegateFullName = (corpo: ICorporation) => {
		return `${corpo.corporationDelegateFirstName} ${corpo.corporationDelegateLastName}`
	}

	const handleSetId = (index: number, id: string) => {
		dispatch(setIdFile({ index: index, value: id }))
	}

	const handleSetKbis = (path: string) => {
		dispatch(setCorporationKbisFile(path))
	}

	const handleSetStatus = (path: string) => {
		dispatch(setCorporationStatutsFile(path))
	}

	const handleSetNonFinancingDeclaration = (index: number, path: string) => {
		if (props.buyerType === 'person') {
			dispatch(setNonFinancingDeclarationFile({ index: index, value: path }))
		} else {
			dispatch(setCorporationNonFinancingDeclarationFile({ index: index, value: path }))
		}
	}

	const handleSetCorpoId = (index: number, id: string) => {
		dispatch(setCorporationDelegateIdFile({ index: index, value: id }))
	}

	return (
		<div className='section'>
			<Title>Vos documents</Title>
			<div className='grid'>
				{props.buyerType === 'person' &&
					persons.map((person, index) => (
						<File
							key={index}
							label={`Pièce d’identité: ${fullName(person)}`}
							placeholder={add}
							id='id'
							onChange={id => handleSetId(index, id)}
							style={{ cursor: 'pointer' }}
						/>
					))}
				{props.buyerType === 'corporation' &&
					corporations.map((corporation, index) => (
						<File
							key={index}
							label={`Pièce d’identité: ${corpoDelegateFullName(corporation)}`}
							placeholder={add}
							id='id'
							onChange={id => handleSetCorpoId(index, id)}
							style={{ cursor: 'pointer' }}
						/>
					))}
				{props.buyerType === 'corporation' && (
					<File
						label='Kbis'
						placeholder={add}
						id='kbis'
						onChange={kbis => handleSetKbis(kbis)}
						style={{ cursor: 'pointer' }}
					/>
				)}
				{props.buyerType === 'corporation' && (
					<File
						label='Status'
						placeholder={add}
						id='status'
						onChange={status => handleSetStatus(status)}
						style={{ cursor: 'pointer' }}
					/>
				)}
				{props.fundingType === 'cash' &&
					props.buyerType === 'person' &&
					persons.map((person, index) => (
						<File
							key={index}
							label={`Renonciation de financement: ${fullName(person)}`}
							placeholder={add}
							id='id'
							onChange={id => handleSetNonFinancingDeclaration(index, id)}
							style={{ cursor: 'pointer' }}
						/>
					))}
				{props.fundingType === 'cash' &&
					props.buyerType === 'corporation' &&
					corporations.map((corporation, index) => (
						<File
							key={index}
							label={`Renonciation de financement: ${corpoDelegateFullName(corporation)}`}
							placeholder={add}
							id='id'
							onChange={id => handleSetNonFinancingDeclaration(index, id)}
							style={{ cursor: 'pointer' }}
						/>
					))}
			</div>
		</div>
	)
}

export default Files
