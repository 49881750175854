import { createSlice } from '@reduxjs/toolkit'

type FundingState = {
	contributionAmount: string
	loanAmount: string
	loanDuration: string
	interestRate: string
}

const initialState: FundingState = {
	contributionAmount: '',
	loanAmount: '',
	loanDuration: '',
	interestRate: '',
}

export const fundingSlice = createSlice({
	name: 'Funding',
	initialState,
	reducers: {
		setContributionAmount: (state, action) => {
			state.contributionAmount = action.payload
		},
		setLoanAmount: (state, action) => {
			state.loanAmount = action.payload
		},
		setLoanDuration: (state, action) => {
			state.loanDuration = action.payload
		},
		setInterestRate: (state, action) => {
			state.interestRate = action.payload
		},
	},
})

export const { setContributionAmount, setLoanAmount, setLoanDuration, setInterestRate } =
	fundingSlice.actions

export default fundingSlice.reducer
