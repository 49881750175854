import React from 'react'

import LabelAndTextInput from '../inputs/LabelAndTextInput'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import house from '@assets/form/house.svg'
import room4 from '@assets/form/number-4.svg'
import padlock from '@assets/form/padlock.svg'

import './Section.css'

function Section1() {
	return (
		<div className='section1'>
			<div className='sectionHeader'>
				<img id='lock' src={padlock} alt='padlock' />
				<Title>Récapitulatif de votre bien</Title>
				<h3>(partie pré-remplie par votre conseiller)</h3>
			</div>
			<div className='pictograms'>
				<Pictogram className='picto0' src={house} id='house'>
					Maison
				</Pictogram>
				<Pictogram className='picto0' src={room4} id='room4'>
					4 pièces
				</Pictogram>
			</div>
			<div className='formContent'>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='residenceType'
						label='Type de résidence'
						placeholder='Neuf accession libre'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('redidenceType: ' + value)}
					/>
					<LabelAndTextInput
						id='id'
						label='Numéro de lot'
						placeholder='no 008'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('id: ' + value)}
					/>
					<LabelAndTextInput
						id='area'
						label='Superficie'
						placeholder='100 m²'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('area: ' + value)}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='parking'
						label='Parking'
						placeholder='1 place extérieure'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('parking: ' + value)}
					/>
					<LabelAndTextInput
						id='usage'
						label='Usage du bien'
						placeholder='Résidence principale'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('usage: ' + value)}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id='vat'
						label='Taux de TVA'
						placeholder='20 %'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('vat: ' + value)}
					/>
					<LabelAndTextInput
						id='price'
						label='Prix'
						placeholder='450 000 €'
						readOnly={{ readOnly: true }}
						onChange={value => console.log('price: ' + value)}
					/>
				</div>
			</div>
		</div>
	)
}

export default Section1
