import { useEffect } from 'react'

import LabelAndPhoneInput from '../inputs/LabelAndPhoneInput'
import LabelAndTextInput from '../inputs/LabelAndTextInput'
import ButtonIconText from '@components/form/buttons/ButtonIconText'
import Title from '../title/Title'
import Pictogram from '@components/form/pictogram/Pictogram'
import person from '@assets/form/person.svg'
import deleteicon from '@assets/form/DeleteIcon.svg'
import corporation from '@assets/form/moral.svg'
import SelectBoxAndLabel from '../select/SelectBoxAndLabel'
import RadioGroupAndLabel from '../radio/RadioGroupAndLabel'
import { useAppDispatch, useAppSelector } from '@/hooks'
import {
	addPerson,
	setAddress,
	setBirthDate,
	setBirthCity,
	setBirthCountry,
	setChildren,
	setContractDate,
	setContractPlace,
	setContractType,
	setEmail,
	setFirstName,
	setGender,
	setHasContract,
	setLastName,
	setMarriageCity,
	setMarriageDate,
	setMarried,
	setNationality,
	setPhone,
	setProfession,
} from '@components/form/sections/personSlice'
import {
	addCorporation,
	setCorporationAddress,
	setCorporationCapital,
	setCorporationDelegateAddress,
	setCorporationDelegateEmail,
	setCorporationDelegateFirstName,
	setCorporationDelegateGender,
	setCorporationDelegateLastName,
	setCorporationDelegatePhone,
	setCorporationName,
	setCorporationRegistar,
	setCorporationSiren,
} from '@components/form/sections/corporationSlice'
import type { RootState } from '@/store'
import {
	defaultInputValidator,
	emailValidator,
	requiredValidator,
	defaultNoNumberInputValidator,
	numberValidator,
} from '@/utils/validators'

import './Section.css'

interface Section2Props {
	index: number
	onRemoveBuyer: (index: number) => void
	buyerType: string
	onSetBuyerType: (value: string) => void
}

function Section2(props: Section2Props) {
	const dispatch = useAppDispatch()

	const handleRemoveBuyer = () => {
		props.onRemoveBuyer(props.index)
	}

	const handleSetBuyerType = (value: string) => {
		props.onSetBuyerType(value)
	}

	useEffect(() => {
		dispatch(addPerson(props.index))
		dispatch(addCorporation(props.index))
	}, [dispatch, props.index])

	const getPersons = useAppSelector((state: RootState) => state.person.persons)

	return (
		<div className='section'>
			<div className='headerSection'>
				<Title>Vos coordonnées</Title>
				{getPersons.length > 1 && (
					<ButtonIconText
						id='supp'
						icon={deleteicon}
						text='Supprimer ce co-acquéreur'
						onClick={handleRemoveBuyer}
					/>
				)}
			</div>
			<div className='pictograms'>
				{(props.index === 0 || props.buyerType === 'person') && (
					<Pictogram
						className={'picto1' + (props.buyerType === 'person' ? ' active' : '')}
						src={person}
						id={computeID(props.index, 'person')}
						onChange={() => handleSetBuyerType('person')}
						style={{ cursor: 'pointer' }}
					>
						Personne physique
					</Pictogram>
				)}
				{(props.index === 0 || props.buyerType === 'corporation') && (
					<Pictogram
						className={'picto1' + (props.buyerType === 'corporation' ? ' active' : '')}
						src={corporation}
						id={computeID(props.index, 'corporation')}
						onChange={() => handleSetBuyerType('corporation')}
						style={{ cursor: 'pointer' }}
					>
						Personne morale
					</Pictogram>
				)}
			</div>
			{props.buyerType === 'person' && <Person index={props.index} />}
			{props.buyerType === 'corporation' && <Corporation index={props.index} />}
		</div>
	)
}

interface PersonProps {
	index: number
}

function computeID(index: number, name: string): string {
	return `${name}-${index}`
}

function Person(props: PersonProps) {
	const dispatch = useAppDispatch()

	const getGender = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.gender ?? '',
	)
	const onSetGender = (value: string) => {
		dispatch(setGender({ index: props.index, value }))
	}

	const getLastName = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.lastName ?? '',
	)
	const onSetLastName = (value: string) => {
		dispatch(setLastName({ index: props.index, value }))
	}

	const getFirstName = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.firstName ?? '',
	)
	const onSetFirstName = (value: string) => {
		dispatch(setFirstName({ index: props.index, value }))
	}

	const getPhone = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.phone ?? '',
	)

	const onSetPhone = (value: string | undefined) => {
		dispatch(setPhone({ index: props.index, value }))
	}

	const getEmail = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.email ?? '',
	)
	const onSetEmail = (value: string) => {
		dispatch(setEmail({ index: props.index, value }))
	}

	const getBirthDate = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.birthDate ?? '',
	)
	const onSetBirthDate = (value: string) => {
		dispatch(setBirthDate({ index: props.index, value }))
	}

	const getBirthCity = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.birthCity ?? '',
	)
	const onSetBirthCity = (value: string) => {
		dispatch(setBirthCity({ index: props.index, value }))
	}

	const getBirthCountry = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.birthCountry ?? '',
	)
	const onSetBirthCountry = (value: string) => {
		dispatch(setBirthCountry({ index: props.index, value }))
	}

	const getNationality = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.nationality ?? '',
	)
	const onSetNationality = (value: string) => {
		dispatch(setNationality({ index: props.index, value }))
	}

	const getProfession = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.profession ?? '',
	)
	const onSetProfession = (value: string) => {
		dispatch(setProfession({ index: props.index, value }))
	}

	const getAddress = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.address ?? '',
	)
	const onSetAddress = (value: string) => {
		dispatch(setAddress({ index: props.index, value }))
	}

	const getMarried = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.married ?? '',
	)
	const onSetMarried = (value: string) => {
		dispatch(setMarried({ index: props.index, value }))
	}

	const getChildren = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.children ?? '',
	)
	const onSetChildren = (value: string) => {
		dispatch(setChildren({ index: props.index, value }))
	}

	const getHasContract = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.hasContract ?? '',
	)
	const onSetHasContract = (value: string) => {
		dispatch(setHasContract({ index: props.index, value }))
	}

	return (
		<div>
			<div className='formContent'>
				<div className='fitContentLine'>
					<SelectBoxAndLabel
						id={computeID(props.index, 'personGender')}
						text='Civilité'
						options={{ '': 'Sélectionner', mr: 'M', mrs: 'Mme' }}
						value={getGender}
						validator={(input: string) => [requiredValidator(input)]}
						onChange={onSetGender}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personLastname')}
						label='Nom'
						name='lastName'
						autocomplete='family-name'
						placeholder='Ex : Dupont'
						value={getLastName}
						validator={defaultNoNumberInputValidator}
						onChange={onSetLastName}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personFirstname')}
						label='Prénom'
						name='firstName'
						autocomplete='given-name'
						placeholder='Ex : Jean'
						value={getFirstName}
						validator={defaultNoNumberInputValidator}
						onChange={onSetFirstName}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndPhoneInput
						id={computeID(props.index, 'personPhone')}
						label='Téléphone'
						name='phone'
						placeholder='06xxxxxxxx'
						value={getPhone}
						onChange={onSetPhone}
						required={true}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personEmail')}
						label='Adresse mail'
						name='email'
						autocomplete='email'
						value={getEmail}
						placeholder='jean.dupont@gmail.com'
						validator={(input: string) => [emailValidator(input)]}
						onChange={onSetEmail}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personBirthDate')}
						type='date'
						name='birthDate'
						autocomplete='bday'
						label='Date de naissance'
						placeholder='jj/mm/aaaa'
						value={getBirthDate}
						validator={(input: string) => [requiredValidator(input)]}
						onChange={onSetBirthDate}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personNationality')}
						label='Nationalité'
						name='nationality'
						autocomplete='nationality'
						placeholder='Ex : Française'
						value={getNationality}
						validator={defaultNoNumberInputValidator}
						onChange={onSetNationality}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personBirthCity')}
						label='Ville de naissance'
						name='city'
						autocomplete='address-level2'
						placeholder='Ex : Paris'
						value={getBirthCity}
						validator={defaultNoNumberInputValidator}
						onChange={onSetBirthCity}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personBirthCountry')}
						name='country'
						label='Pays de naissance'
						autocomplete='country'
						placeholder='Ex : France'
						value={getBirthCountry}
						validator={defaultNoNumberInputValidator}
						onChange={onSetBirthCountry}
					/>
				</div>
				<div className='fieldLine'>
					<LabelAndTextInput
						id={computeID(props.index, 'personProfession')}
						label='Profession'
						name='job'
						autocomplete='job'
						placeholder='Ex : artisan'
						value={getProfession}
						validator={defaultNoNumberInputValidator}
						onChange={onSetProfession}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personAddress')}
						label='Adresse'
						name='address'
						autocomplete='street-address'
						placeholder='Ex : 12 rue de Paris 75000 Paris'
						value={getAddress}
						validator={defaultInputValidator}
						onChange={onSetAddress}
					/>
				</div>
				<div className='fieldLine'>
					<SelectBoxAndLabel
						id={computeID(props.index, 'personMarried')}
						text='Status marital'
						options={{
							'': 'Sélectionner',
							single: 'Célibataire',
							married: 'Marié',
							civilUnion: 'Pacsé',
							divorced: 'Divorcé',
							widowhood: 'Veuf',
						}}
						value={getMarried}
						validator={(input: string) => [requiredValidator(input)]}
						onChange={onSetMarried}
					/>
					<LabelAndTextInput
						id={computeID(props.index, 'personChildren')}
						type='number'
						label='Nombre d’enfants'
						name='children'
						autocomplete='number'
						placeholder='Ex : 0'
						value={getChildren}
						validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
						onChange={onSetChildren}
					/>
				</div>
				{(getMarried === 'married' || getMarried === 'civilUnion') && (
					<Marriage index={props.index} />
				)}
				{(getMarried === 'married' || getMarried === 'civilUnion') && (
					<div className='fieldLine'>
						<RadioGroupAndLabel
							id={computeID(props.index, 'personHasContract')}
							label='Avez-vous un contrat de mariage / PACS ?'
							options={{ no: 'Non', yes: 'Oui' }}
							defaultChecked={getHasContract}
							validator={(input: string) => [requiredValidator(input)]}
							onChange={onSetHasContract}
						/>
					</div>
				)}
			</div>
			{getHasContract === computeID(props.index, 'personHasContract') + '-yes' && (
				<Contract index={props.index} />
			)}
		</div>
	)
}

interface MarriageProps {
	index: number
}

function Marriage(props: MarriageProps) {
	const dispatch = useAppDispatch()

	const getMarriageCity = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.marriageCity ?? '',
	)
	const onSetMarriageCity = (value: string) => {
		dispatch(setMarriageCity({ index: props.index, value }))
	}

	const getMarriageDate = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.marriageDate ?? '',
	)
	const onSetMarriageDate = (value: string) => {
		dispatch(setMarriageDate({ index: props.index, value }))
	}

	return (
		<div
			className='formContent'
			style={{
				margin: '0',
			}}
		>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'personMarriageCity')}
					label='Ville du mariage / PACS'
					name='city'
					placeholder='Ville'
					value={getMarriageCity}
					onChange={onSetMarriageCity}
					validator={defaultNoNumberInputValidator}
					autocomplete='address-level2'
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'personMarriageDate')}
					type='date'
					name='marriageDate'
					label='Date du mariage / PACS'
					placeholder='jj/mm/aaaa'
					value={getMarriageDate}
					onChange={onSetMarriageDate}
					validator={(input: string) => [requiredValidator(input)]}
				/>
			</div>
		</div>
	)
}

interface ContractProps {
	index: number
}

function Contract(props: ContractProps) {
	const dispatch = useAppDispatch()

	const getContractDate = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.contractDate ?? '',
	)
	const onSetContractDate = (value: string) => {
		dispatch(setContractDate({ index: props.index, value }))
	}

	const getContractPlace = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.contractPlace ?? '',
	)
	const onSetContractPlace = (value: string) => {
		dispatch(setContractPlace({ index: props.index, value }))
	}

	const getContractType = useAppSelector(
		(state: RootState) => state.person.persons[props.index]?.contractType ?? '',
	)
	const onSetContractType = (value: string) => {
		dispatch(setContractType({ index: props.index, value }))
	}

	return (
		<div className='formContent'>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'contractDate')}
					type='date'
					name='contractDate'
					label='Date du contrat'
					placeholder='jj/mm/aaaa'
					value={getContractDate}
					onChange={onSetContractDate}
					validator={(input: string) => [requiredValidator(input)]}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'contractPlace')}
					label='Lieu de signature'
					name='city'
					placeholder='Ville'
					value={getContractPlace}
					onChange={onSetContractPlace}
					validator={defaultNoNumberInputValidator}
					autocomplete='address-level2'
				/>
				<SelectBoxAndLabel
					id={computeID(props.index, 'contractType')}
					text='Régime'
					options={{
						'': 'Sélectionner',
						universalCommunity: 'Communauté universelle',
						separated: 'Séparation de biens',
						participation: 'Participation aux acquêts',
						acquittalsCommunity: 'Communauté réduite aux acquêts',
					}}
					value={getContractType}
					validator={(input: string) => [requiredValidator(input)]}
					onChange={onSetContractType}
				/>
			</div>
		</div>
	)
}

interface CorporationProps {
	index: number
}

function Corporation(props: CorporationProps) {
	const dispatch = useAppDispatch()

	const getCorporationName = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index]?.corporationName ?? '',
	)
	const onSetCorporationName = (value: string) => {
		dispatch(setCorporationName({ index: props.index, value }))
	}

	const getCorporationCapital = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index]?.corporationCapital ?? '',
	)
	const onSetCorporationCapital = (value: string) => {
		dispatch(setCorporationCapital({ index: props.index, value }))
	}

	const getCorporationAddress = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index]?.corporationAddress ?? '',
	)
	const onSetCorporationAddress = (value: string) => {
		dispatch(setCorporationAddress({ index: props.index, value }))
	}

	const getCorporationSiren = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index]?.corporationSiren ?? '',
	)
	const onSetCorporationSiren = (value: string) => {
		dispatch(setCorporationSiren({ index: props.index, value }))
	}

	const getCorporationRegistar = useAppSelector(
		(state: RootState) => state.corporation.corporations[props.index]?.corporationRegistar ?? '',
	)
	const onSetCorporationRegistar = (value: string) => {
		dispatch(setCorporationRegistar({ index: props.index, value }))
	}

	const getCorporationDelegateGender = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.index]?.corporationDelegateGender ?? '',
	)
	const onSetCorporationDelegateGender = (value: string) => {
		dispatch(setCorporationDelegateGender({ index: props.index, value }))
	}

	const getCorporationDelegateLastName = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.index]?.corporationDelegateLastName ?? '',
	)
	const onSetCorporationDelegateLastName = (value: string) => {
		dispatch(setCorporationDelegateLastName({ index: props.index, value }))
	}

	const getCorporationDelegateFirstName = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.index]?.corporationDelegateFirstName ?? '',
	)
	const onSetCorporationDelegateFirstName = (value: string) => {
		dispatch(setCorporationDelegateFirstName({ index: props.index, value }))
	}

	const getCorporationDelegatePhone = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.index]?.corporationDelegatePhone ?? '',
	)
	const onSetCorporationDelegatePhone = (value: string | undefined) => {
		dispatch(setCorporationDelegatePhone({ index: props.index, value }))
	}

	const getCorporationDelegateEmail = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.index]?.corporationDelegateEmail ?? '',
	)
	const onSetCorporationDelegateEmail = (value: string) => {
		dispatch(setCorporationDelegateEmail({ index: props.index, value }))
	}

	const getCorporationDelegateAddress = useAppSelector(
		(state: RootState) =>
			state.corporation.corporations[props.index]?.corporationDelegateAddress ?? '',
	)
	const onSetCorporationDelegateAddress = (value: string) => {
		dispatch(setCorporationDelegateAddress({ index: props.index, value }))
	}

	return (
		<div className='formContent'>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationName')}
					label='Raison sociale'
					name='corporationName'
					autocomplete='organization'
					placeholder="Ex : SAS de l'Étoile"
					value={getCorporationName}
					onChange={onSetCorporationName}
					validator={defaultNoNumberInputValidator}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationCapital')}
					label='Capital social'
					name='corporationCapital'
					placeholder='Ex : 1 500€'
					value={getCorporationCapital}
					onChange={onSetCorporationCapital}
					validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
					autocomplete='on'
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationAddress')}
					name='address'
					autocomplete='street-address'
					label='Adresse de la société'
					placeholder='Ex : chemin de l’Etoile 06000 Nice'
					value={getCorporationAddress}
					onChange={onSetCorporationAddress}
					validator={defaultInputValidator}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationSiren')}
					label='SIREN'
					name='siren'
					placeholder='Ex : 123456789'
					value={getCorporationSiren}
					onChange={onSetCorporationSiren}
					validator={(input: string) => [requiredValidator(input), numberValidator(input)]}
					autocomplete='on'
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationRegistar')}
					label='Ville où a été enregistrée la société'
					name='city'
					placeholder='Ex : Nice'
					value={getCorporationRegistar}
					onChange={onSetCorporationRegistar}
					validator={defaultNoNumberInputValidator}
					autocomplete='address-level2'
				/>
			</div>
			<div className='underSectionSeparator'>Représentant de la société</div>
			<div className='fitContentLine'>
				<SelectBoxAndLabel
					id={computeID(props.index, 'corporationDelegateGender')}
					text='Civilité'
					options={{ '': 'Sélectionner', mr: 'M', mrs: 'Mme' }}
					value={getCorporationDelegateGender}
					validator={(input: string) => [requiredValidator(input)]}
					onChange={onSetCorporationDelegateGender}
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateLastname')}
					label='Nom'
					name='lastName'
					placeholder='Ex : Dupont'
					value={getCorporationDelegateLastName}
					onChange={onSetCorporationDelegateLastName}
					validator={defaultNoNumberInputValidator}
					autocomplete='family-name'
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateFirstname')}
					label='Prénom'
					name='firstName'
					placeholder='Ex : Jean'
					value={getCorporationDelegateFirstName}
					onChange={onSetCorporationDelegateFirstName}
					validator={defaultNoNumberInputValidator}
					autocomplete='given-name'
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndPhoneInput
					id={computeID(props.index, 'corporationDelegatePhone')}
					label='Téléphone'
					name='phone'
					placeholder='06xxxxxxxx'
					value={getCorporationDelegatePhone}
					onChange={onSetCorporationDelegatePhone}
					required={true}
				/>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateEmail')}
					label='Adresse mail'
					name='email'
					placeholder='Ex : jean.dupont@leficaneur.com'
					value={getCorporationDelegateEmail}
					onChange={onSetCorporationDelegateEmail}
					validator={(input: string) => [emailValidator(input)]}
					autocomplete='email'
				/>
			</div>
			<div className='fieldLine'>
				<LabelAndTextInput
					id={computeID(props.index, 'corporationDelegateAddress')}
					label='Adresse'
					name='address'
					placeholder="Ex : Chemin de l'Étoile 06000 Nice"
					value={getCorporationDelegateAddress}
					onChange={onSetCorporationDelegateAddress}
					validator={defaultInputValidator}
					autocomplete='street-address'
				/>
			</div>
		</div>
	)
}

export default Section2
